/**@jsx jsx*/
import React, { useEffect } from "react"
import { useGlobalState, useGlobalDispatch } from "../utilities/globalContext"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"
import axios from "axios"
import queryString from "query-string"
import { platform } from "../utilities/terms"
import { Row } from "boostly-ui2"
import { Link } from "gatsby"

import Layout from "../components/layout"
import { TitleSecondary, TitleTertiary, Caption } from "../components/titles"
import { serverURL } from "../../variables"
import { medMT, smallMT } from "../components/spacing"

const CLIENT_ID = "ca_GNa6GpQEfb9VQwKRcJFV1PP3uNxCNkC3"
const STATE = "swagmeister"
const oauthURL = `${serverURL}/api/stripe/oauth`
const updateUserURL = `${serverURL}/api/users/`
const disabledCSS = css`
  pointer-events: none;
  filter: brightness(0.6);
`

const bold = css`
  font-weight: 400;
`
const Message = styled(Caption)`
  color: hsl(180, 90%, 35%);
  transform: translateY(8px);
  margin-left: 12px;
`
const Content = styled.section`
  ${smallMT}
  grid-column-start: 2;
  grid-row-start: 2;
  z-index: 2;
  display: flex;
  flex-direction: column;
`
const StripeConnectButton = ({ disabled, ...props }) => {
  const Button = styled.button`
    ${smallMT}
    cursor: pointer;
    background: url(${require("../images/connect.png")});
    background-position: center;
    background-repeat: none;
    background-size: cover;
    height: 40px;
    width: 250px;
    border-radius: 8px;
    border: none;
    @media (max-width: 500px) {
      align-self: flex-start;
    }
  `
  return <Button {...props} />
}
const signupURL = clientId =>
  `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write&state=${STATE}`

const Setup = () => {
  const { user } = useGlobalState()
  const globalDispatch = useGlobalDispatch()

  const UpdateWithStripeInfo = code => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.post(oauthURL, {
          authorizationCode: code,
        })

        console.log("return from axios post")
        const { stripeUserId, stripeRefreshToken } = res.data
        if (!stripeUserId) {
          console.log("Stripe user id wasn't returned.")
          return
        }

        console.log("hi")

        await axios({
          method: "put",
          url: updateUserURL,
          data: {
            id: user.id,
            stripeUserId: stripeUserId,
            stripeRefreshToken: stripeRefreshToken,
          },
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        })

        console.log("return from axios put")

        globalDispatch({
          type: "SET_USER",
          user: {
            ...user,
            stripeUserId: stripeUserId,
            stripeRefreshToken: stripeRefreshToken,
          },
        })
        resolve()
      } catch (e) {
        console.log(e)
        reject(e)
      }
    })
  }
  useEffect(() => {
    if (!user) {
      typeof window !== "undefined" && (window.location.href = `/signin`)
    }
    const queryParams =
      typeof window !== "undefined" && queryString.parse(window.location.search)
    if (!queryParams) {
      return
    }

    const { error, error_description: errorDescription } = queryParams
    if (error) {
      console.log(errorDescription)
      return
    }
    const { code, state } = queryParams
    if (!state || state !== STATE) {
      console.log("The returned token doesn't match")
      return
    }

    UpdateWithStripeInfo(code)
      .then(() => {
        console.log("everything went smoothly")
      })
      .catch(e => console.log(e))
  }, [])
  const handleClick = e => {
    //TOOD: pass a token in state to prevent CSRF attacks https://stripe.com/docs/connect/standard-accounts
    typeof window !== "undefined" &&
      (window.location.href = signupURL(CLIENT_ID))
  }
  return (
    <Layout>
      <Content>
        <TitleSecondary css={bold}>Account Integrations</TitleSecondary>
        <TitleSecondary css={medMT}>Connecting with Stripe</TitleSecondary>
        <TitleTertiary css={smallMT}>
          {platform.name} uses Stripe to handle payment processsing for security
          and convenience. The button below will send you to their website and
          allow you to integrate an existing or new account with our system.{" "}
          <p
            css={css`
              /* text-decoration: underline; */
            `}
          >
            You must have a Stripe account to use Poqet.
          </p>
        </TitleTertiary>
        <Row y>
          <StripeConnectButton
            onClick={handleClick}
            css={user && user.stripeUserId && disabledCSS}
          />
          {user && user.stripeUserId && <Message>✓ You've done this!</Message>}
        </Row>
        <TitleSecondary css={medMT}>
          Zapier Integration (Coming Soon)
        </TitleSecondary>
        <TitleTertiary css={smallMT}>
          Automate everything! With Zapier, you'll be able to have connect
          almost every major platform with {platform.name}! Define custom flows
          such as: adding buyers to mailing lists upon purchase, adding Stripe
          customers to spreadsheets. The possibilities are endless.
        </TitleTertiary>
        <Link css={medMT} to="/dashboard">
          Back to Dashboard
        </Link>
      </Content>
    </Layout>
  )
}

export default Setup
